import { type ProductLabelType } from 'components/ProductCard/types'
import axiosInstanceApiGateway from 'config/apiServiceConfig'

const { Get } = axiosInstanceApiGateway

export type ProductType = {
  productID: number
  productName: string
  productURLImage: string
  productPrice: string
  productStock: number
  productFavorite: boolean
  isTwentyOne: boolean
  productInventoryDiscountId: number
  productDiscountPrice: string
  productDiscountPercentage: string
  productDiscountStock: number
  productDiscountDailyQuota: 0
  availableOnAndroid: boolean
  availableOnIos: boolean
  productVolume: number
  productWeight: string
  astroCoin: number
  productGrammationLabel: string
  productLabels: ProductLabelType[]
  category_ids: number[]
  product_slug: string
  inventoryDiscountTierLabel: {
    discountPercentage: number
    discountPercentageFmt: string
    discountPrice: number
    discountPriceFmt: string
    finalPrice: number
    finalPriceFmt: string
    label: string
    qty: number
  }[]
}

export type ComponentVariantType =
  | 'article'
  | 'banner'
  | 'masthead'
  | 'dynamic-channel'
  | 'product-tabbing-category'

export type BannerLayoutType =
  | 'banner-headline'
  | 'banner-2x2'
  | 'banner-4x4'
  | 'banner-slide'
  | 'banner-carousel'

export type ImageViewportType = 'small' | 'medium'

export const MastheadLayoutTypes = ['masthead-headline', 'masthead-carousel'] as const

export type MastheadLayoutType = (typeof MastheadLayoutTypes)[number]

export type ImageType = {
  url: string
  title: string
  width: number
  height: number
  link: string
  viewport: ImageViewportType
  imageId: number
}

export type ArticleComponentSectionType = {
  title?: string
  body: string
}

export type BannerComponentSectionType = {
  layout: BannerLayoutType
  items: ImageType[]
}

export type DynamicChannelComponentSectionType = {
  id: number
  title: string
  totalPage: number
  products: ProductType[]
}

export type MastheadComponentSectionType = {
  layout: MastheadLayoutType
  items: ImageType[]
}

export type ProductTabbingComponentSectionType = {
  tabbings: {
    id: number
    name: string
  }[]
}

export type ComponentSectionDataType = {
  id: number
  type: ComponentVariantType
  data:
    | ArticleComponentSectionType
    | BannerComponentSectionType
    | DynamicChannelComponentSectionType
    | MastheadComponentSectionType
    | ProductTabbingComponentSectionType
}

export type DynamicLandingType = {
  id: number
  name: string
  metaDescription: string
  metaKeyword: string
  seoStatus: {
    id: number
    name: string
    value: string
  }
  ogImageUrl: string
  ogImageHeight: number
  ogImageWidth: number
  isAcive: boolean
  components: ComponentSectionDataType[]
}

export type GetDynamicLandingDetailResponseType = {
  data: DynamicLandingType
}

export type GetDynamicLandingDetailParamsType = {
  isPreview?: boolean
  location_ids?: string
}

export const getDynamicLandingDetail = (slug: string, params?: GetDynamicLandingDetailParamsType) =>
  Get<GetDynamicLandingDetailResponseType, true>({
    url: `/api/dynamic-landing/${slug}`,
    params,
  })
